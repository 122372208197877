import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import { INewsItem } from '../../types'
import LogoNews from '../../svg/logo-news'
import LogoEn from '../../svg/logo-en'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'
import { useTranslations } from '../../hooks/use-translations'

import * as s from './News.module.sass'

interface IProps {
  data: INewsItem
  className?: string
  bodyClassName?: string
  imageWrapClassName?: string
}

export const NewsItem: React.FC<IProps> = ({
  data,
  className,
  imageWrapClassName,
  bodyClassName,
}) => {
  const { t, getPath } = useTranslations()
  const { region } = React.useContext(GlobalContext)
  return (
    <Link
      to={getPath(data.to)}
      title={t(data.title)}
      className={cn(s.box, className)}
    >
      <div className={cn(s.imageWrap, imageWrapClassName)}>
        {!data.image ? (
          region === AppRegionEnum.Ru ? (
            <LogoNews className={s.logo} />
          ) : (
            <LogoEn className={s.logo} />
          )
        ) : (
          <GatsbyImage
            image={data.image}
            alt={t(data.title)}
            className={s.image}
            title={t(data.title)}
          />
        )}
      </div>
      <div className={cn(s.body, bodyClassName)}>
        {data.type && <div className={s.type}>{data.type}</div>}
        {data.date && <div className={s.date}>{data.date}</div>}
        <h2 className={s.textTitle}>{t(data.title)}</h2>
      </div>
    </Link>
  )
}

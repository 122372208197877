import React from 'react'
import { useTranslations } from '../hooks/use-translations'
import SEO from '../components/seo'
import News from '../components/News'
import { useNewsData } from '../hooks/useNewsData'
import { GlobalContext } from '../context/GlobalContext'
import { AppRegionEnum } from '../types/app-region.enum'
import SgnlImageSeo from '../assets/images/seo/sgnl/news.png'
import SgnxImageSeo from '../assets/images/seo/sgnx/news.png'

const NewsPage: React.FC = () => {
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)
  const news = useNewsData(region as AppRegionEnum)
  const title = t('News')

  return (
    <>
      <SEO
        title={title}
        description={t('_news__lead')}
        image={region === AppRegionEnum.Ru ? SgnlImageSeo : SgnxImageSeo}
      />
      <News title={title} items={news} />
    </>
  )
}

export default NewsPage

import React from 'react'

const LogoNews: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="82"
    height="102"
    viewBox="0 0 82 102"
    fill="none"
    color="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.3904 3.00586C22.2446 4.06198 22.1821 5.15871 22.1821 6.27576C22.1821 17.893 30.3051 25.9561 46.447 30.4649L50.9667 31.6429C52.9454 32.1709 54.3409 32.4959 55.1532 32.8208C55.9655 33.0442 57.0069 33.3489 58.0483 33.7957C60.2561 34.6487 60.8393 35.847 60.8393 37.2281C60.8393 39.8075 58.1733 41.1073 52.9454 41.1073C45.8638 41.1073 40.99 37.878 38.324 31.5413L18.4746 42.1837C22.5362 53.801 34.7207 61.1126 52.3622 61.1126C55.3406 61.1126 58.1316 60.9095 60.756 60.5033L0 78.2745L22.3904 3.00586Z"
      fill="currentColor"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.6925 10.3784C60.4847 5.1181 56.7773 2.41688 51.5494 2.41688C47.4879 2.41688 45.4051 4.14323 45.4051 6.39763C45.4051 8.00211 46.3424 9.2004 48.0711 10.0534C49.9248 10.9064 53.4031 11.9829 58.631 13.3842C61.8594 14.2779 63.3174 14.6435 65.6501 15.5168L82.0003 0H81.8753L62.6925 10.3784Z"
      fill="currentColor"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.93468 84.3264C8.16355 84.0624 9.18413 84.4483 10.0797 84.9763V80.9549C8.7884 80.6097 7.70533 80.5894 6.2057 80.9753C2.45662 81.9705 0.457108 85.0169 0.457108 87.901C0.457108 90.5616 2.04005 91.6786 4.51861 92.8566C5.2476 93.2222 6.47647 93.7299 6.8722 94.4408C7.03883 94.7657 7.10131 95.0704 7.10131 95.436C7.10131 96.817 6.01824 97.7107 4.51861 97.7716C3.28975 97.8325 2.26916 97.4264 1.26941 96.9186V100.412C2.39413 101.042 3.72714 101.366 5.08097 101.366C9.07999 101.366 11.3711 98.4825 11.3711 94.847C11.3711 88.9571 4.74772 90.0132 4.74772 86.6621C4.74772 85.5653 5.64333 84.6108 6.93468 84.3264Z"
      fill="currentColor"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2458 101.042L18.6197 101.022V78.0513L14.2458 79.1886V101.042Z"
      fill="currentColor"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.783 85.5859C36.8246 86.2764 36.8246 86.9872 36.8246 87.6981C36.8246 89.6478 36.7622 91.6382 36.4289 93.5677C35.6999 97.8328 33.4921 101.407 29.743 101.407C23.7862 101.407 21.8908 94.4816 21.8908 89.201C21.8908 83.7377 23.5362 76.4261 29.743 74.781C33.1172 73.8873 35.3042 75.9183 36.2206 79.5741L31.9925 82.4175C31.7842 80.9146 31.4301 78.9039 29.7847 79.2695C26.8063 79.9194 26.5772 86.1545 26.5772 88.5105C26.5772 90.9883 26.6813 96.8985 29.8264 96.7563C32.2008 96.6548 32.4715 93.0599 32.5132 90.9071L29.743 91.1914V86.6217L36.783 85.5859Z"
      fill="currentColor"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.6315 100.879L44.1953 83.1286C44.3619 84.205 44.4244 85.3424 44.4244 86.4594V100.9L40.0505 100.92V72.4862L44.1953 71.4097L49.6315 88.6529C49.4232 87.2921 49.2982 85.9111 49.2982 84.53V70.0693L53.7346 68.9116V100.839L49.6315 100.879Z"
      fill="currentColor"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.2762 100.818L65.4431 94.9486L61.0484 95.1517L60.2569 100.838L55.8829 100.859L61.0484 67.0427L65.6097 65.8647L70.9001 100.798L66.2762 100.818ZM64.7974 89.6274C64.3392 86.3372 63.881 83.1282 63.5477 79.8177C63.4228 78.335 63.277 76.8118 63.1937 75.3495C62.9854 80.2239 62.3605 85.0779 61.7357 89.8914L64.7974 89.6274Z"
      fill="currentColor"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.0646 63.9157V100.778L81.9999 100.758V93.4057L77.4386 93.6495V62.7783L73.0646 63.9157Z"
      fill="currentColor"
      fillOpacity="0.3"
    />
  </svg>
)

export default LogoNews

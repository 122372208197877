// extracted by mini-css-extract-plugin
export var title = "News-module--title--LZws8";
export var content = "News-module--content--38iFs";
export var box = "News-module--box--zGGHr";
export var image = "News-module--image--2ADUs";
export var textTitle = "News-module--textTitle--lvoc0";
export var imageWrap = "News-module--imageWrap--D7tym";
export var body = "News-module--body--ewLc-";
export var type = "News-module--type--NY4CU";
export var date = "News-module--date--LLsTg";
export var logo = "News-module--logo--EBGe9";
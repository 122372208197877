import React from 'react'
import Section from '../Common/Section'
import Container from '../Common/Container'
import { NewsItem } from './NewsItem'
import { INewsItem } from '../../types'

import * as s from './News.module.sass'

interface IProps {
  title: string
  items: INewsItem[]
}

const News: React.FC<IProps> = ({ title, items }) => {
  return (
    <Section>
      <Container>
        <h1 className={s.title}>{title}</h1>
        <div className={s.content}>
          {items.map(item => (
            <NewsItem data={item} key={item.id} />
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default News
